import { select, event } from "d3-selection"
import { scaleTime } from "d3-scale"
import { axisBottom } from "d3-axis"

import { Renderer, Rendition } from "../renderer"
import { Widget } from "../widget"
import { trilingual } from "../i18n"

interface Bar {
  start?: string
  assumedStart: string
  end?: string
  openEnded: boolean
  role: {
    name: string
    abbr: string
    cat: number
  }
  title: string
  query: object
  url?: string
  type: string
}

interface Line {
  text: string
  description: string
  bars: Bar[]
}

interface Data {
  lines: Line[]
  startDate: string
  endDate: string
}

export const vita: Renderer = function (widget: Widget, data: Data): Rendition {
  const container = widget.container
  const tickFormat = widget.i18n().timeScaleFormatter
  const margin = { chartLeft: 12, right: 15, top: 0, bottom: 0 }

  const lineHeight = 18
  const itemMargin = 5
  const outerTextMargin = 2
  const maxTriangleWidth = lineHeight * 0.71
  const lines = data.lines

  if (!lines || !lines.length) {
    throw new Error("vita empty")
  }

  const nLines = lines.length
  const height = margin.top + (lineHeight + itemMargin) * nLines + itemMargin

  const beginning = new Date(data.startDate).getTime()
  const ending = new Date(data.endDate).getTime()

  const toDate = (value: string | undefined) => (value ? new Date(value) : null)
  const svg = select(widget.container).append("svg")
  svg.append("title").text(trilingual("History", "Historie", "Historique"))
  const linkifier = widget.getLinkifier()
  const formatDate = (date: Date | null) =>
    date ? date.toLocaleDateString() : ""

  const draw = function () {
    svg.select("g").remove()
    const canvas = svg.append("g")
    const width = container.getBoundingClientRect().width
    svg.attr("width", width)

    const determineTextWidths = function () {
      const texts = []
      for (const line of lines) {
        for (const bar of line.bars) {
          texts.push(bar.role.abbr)
          texts.push(bar.role.abbr + " " + line.text)
        }
      }

      const textsWidth: Record<string, number> = {}
      canvas
        .selectAll(".textWidthDummy")
        .data(texts)
        .enter()
        .append("text")
        .text(function (d) {
          return d
        })
        .each(function (d) {
          textsWidth[d] = (this as SVGTextElement).getComputedTextLength()
          this.remove()
        })
      return textsWidth
    }

    const textsWidths = determineTextWidths()
    const getLabel = function (
      text: string,
      optionalSuffix: string | undefined,
      barWidth: number
    ): { text: string; width: number; containsSuffix?: boolean } {
      if (optionalSuffix) {
        const compoundText = text + " " + optionalSuffix
        const textWidth = textsWidths[compoundText]
        // Require small additional padding for compound labels
        const availableWidth = barWidth - 8
        if (textWidth !== undefined && availableWidth > textWidth) {
          return { text: compoundText, width: textWidth, containsSuffix: true }
        }
      }

      const textWidth = textsWidths[text]
      if (textWidth !== undefined && barWidth > textWidth) {
        return { text: text, width: textWidth }
      }
      return { text: "…", width: 0 }
    }

    const shouldUseNiceScale = function () {
      if (width >= 600) {
        return true
      }

      const paddingMilliseconds = 30 * 86400 * 1000 // 30 days
      for (const line of lines) {
        for (const bar of line.bars) {
          const startTime = toDate(bar.assumedStart)
          const endTime = toDate(bar.end) || new Date()
          if (!startTime) {
            // If start time or end time is missing the bar will
            // latch to the start of the diagram
            return true
          }

          // If one of the bars starts or ends close to the edge
          if (
            Math.abs(startTime.getTime() - beginning) < paddingMilliseconds ||
            Math.abs(ending - endTime.getTime()) < paddingMilliseconds
          ) {
            return true
          }
        }
      }
      return false
    }

    let xScale = scaleTime()
      .domain([beginning, ending])
      .range([margin.chartLeft, width - margin.right])
    if (shouldUseNiceScale()) {
      xScale = xScale.nice()
    }

    const xAxis = axisBottom<Date>(xScale)
      .tickFormat(tickFormat)
      .tickSizeInner(8)
      .tickSizeOuter(10)
      // min 100px distance and ~10 ticks (plus outer ticks and ticks added by nice())
      .ticks(Math.min(width / 100, 10))

    // small ticks
    canvas
      .append("g")
      .attr("class", "axis")
      .attr("transform", "translate(" + 0 + "," + height + ")")
      .call(xAxis)
      .attr("font-size", null)
      .attr("font-family", null)

    // vertical grid lines
    canvas
      .append("g")
      .attr("class", "axis grid")
      .attr("transform", "translate(" + 0 + "," + height + ")")
      .call(xAxis.tickSizeInner(-height).tickSizeOuter(0))
      .call((g) => g.selectAll(".tick text").remove())

    const chartLeft = margin.chartLeft
    const chartRight = width - margin.right

    // draw the chart
    let iLine = 0
    for (const line of lines) {
      const lineTop =
        margin.top + (lineHeight + itemMargin) * iLine + itemMargin
      const lineBottom = lineTop + lineHeight
      const lineYTextBase = lineTop + lineHeight * 0.75 - 1
      const lineYCenter = (lineTop + lineBottom) / 2

      canvas
        .append("line")
        .attr("x1", chartLeft)
        .attr("x2", chartRight)
        .attr("y1", lineYCenter)
        .attr("y2", lineYCenter)
        .attr("class", "grid")

      const nBars = line.bars.length
      let iBar = 0
      let companyNameShown = false
      let freeSpaceLeft = 0
      let freeSpaceRight = 0
      for (const bar of line.bars) {
        const startTime = toDate(bar.assumedStart)
        const endTime = toDate(bar.end) || new Date()
        let barLeft = startTime
          ? (xScale(startTime) as number)
          : margin.chartLeft + maxTriangleWidth
        const openStarted = !bar.start
        if (openStarted) {
          barLeft += maxTriangleWidth
        }
        let barRight = xScale(endTime) as number
        if (bar.openEnded && bar.end) {
          barRight = Math.max(barLeft, barRight - maxTriangleWidth)
        }

        const barWidth = barRight - barLeft
        let textX = barLeft + barWidth / 2
        // Use space provided by open ended bars to make short bars more legible
        if (barWidth < 10) {
          if (openStarted && !bar.openEnded) {
            textX -= 3
          } else if (bar.openEnded && !openStarted) {
            textX += 3
          }
        }

        /*
        let timeInfo: string | null = null
        if (!bar.start && bar.end) {
          timeInfo = "bis " + formatDate(endTime)
        } else if (bar.start && !bar.end) {
          timeInfo = "ab " + formatDate(startTime)
        } else if (bar.start && bar.end) {
          timeInfo = formatDate(startTime) + " – " + formatDate(endTime)
        }
        const title =
          (timeInfo ? timeInfo + " " : "") +
          bar.role.name +
          " " +
          line.description
          */

        const label = getLabel(
          bar.role.abbr,
          !companyNameShown ? line.text : undefined,
          barWidth
        )
        // where do we put the company name?
        if (label.containsSuffix) {
          companyNameShown = true
        }

        if (iBar == 0) {
          const barOuterLeft = openStarted
            ? barLeft - maxTriangleWidth
            : barLeft
          const barOrTextLeft = label.width
            ? Math.min(textX - label.width / 2, barOuterLeft)
            : barOuterLeft
          freeSpaceLeft = barOrTextLeft - chartLeft - outerTextMargin
        }
        if (iBar == nBars - 1) {
          const barOuterRight = bar.openEnded
            ? barRight + maxTriangleWidth
            : barRight
          const barOrTextLeft = label.width
            ? Math.max(textX + label.width / 2, barOuterRight)
            : barOuterRight
          freeSpaceRight = chartRight - barOrTextLeft - outerTextMargin
        }

        const link = canvas
          .append("svg:a")
          //  .attr("xlink:href", linkifier(bar))
          .attr("xlink:href", bar.url || "")
          .attr("xlink:title", bar.title)
          .attr("data-old", bar.end ? true : (null as any))
          .attr("data-clickable", () =>
            widget.getClickHandler("p") ? true : (null as any)
          )
          .on("click", function () {
            const clickEvent = event as Event
            if (widget.invokeClickHandler(bar)) {
              clickEvent.preventDefault()
            }
          })
        link.append("title").text(bar.title)

        if (openStarted) {
          const triangleLeft = barLeft - maxTriangleWidth
          const triangleRight = barLeft
          const triangleTop = lineTop
          const triangleBottom = lineTop + lineHeight
          const points = [
            triangleRight + "," + triangleTop,
            triangleRight + "," + triangleBottom,
            triangleLeft + "," + (triangleTop + triangleBottom) / 2,
          ]
          link
            .append("polygon")
            .attr("points", points.join(" "))
            .attr("class", "in c" + bar.role.cat)
        }

        if (bar.openEnded) {
          const triangleLeft = barRight
          const triangleRight = Math.min(
            maxTriangleWidth + triangleLeft,
            chartRight
          )
          const triangleTop = lineTop
          const triangleBottom = lineTop + lineHeight
          const points = [
            triangleLeft + "," + triangleTop,
            triangleLeft + "," + triangleBottom,
            triangleRight + "," + (triangleTop + triangleBottom) / 2,
          ]
          link
            .append("polygon")
            .attr("points", points.join(" "))
            .attr("class", "out c" + bar.role.cat)
        }

        link
          .append("rect")
          .attr("x", barLeft)
          .attr("y", lineTop)
          .attr("width", barWidth)
          .attr("height", lineHeight)
          .attr("class", "c" + bar.role.cat)

        link
          .append("text")
          .attr("x", textX)
          .attr("y", lineYTextBase)
          .attr("class", "label-text c" + bar.role.cat)
          .text(label.text)

        ++iBar
      } // inner for

      if (!companyNameShown) {
        // look for free space to show name
        if (freeSpaceLeft > freeSpaceRight) {
          if (freeSpaceLeft > 200) {
            // show on the left
            canvas
              .append("text")
              .attr("x", chartLeft + freeSpaceLeft)
              .attr("y", lineYTextBase)
              .attr("class", "left extra")
              .text(line.text)
          }
        } else {
          if (freeSpaceRight > 200) {
            // show on the right
            canvas
              .append("text")
              .attr("x", chartRight - freeSpaceRight)
              .attr("y", lineYTextBase)
              .attr("class", "right extra")
              .text(line.text)
          }
        }
      }

      // ffd END
      ++iLine
    } // outer for

    const today = new Date()
    const todayTitle = "Heute, " + formatDate(today)
    const xToday = xScale(today) as number
    const todayParent = canvas.append("svg:a").attr("xlink:title", todayTitle)
    todayParent.append("title").text(todayTitle)
    todayParent
      .append("line")
      .attr("class", "today")
      .attr("x1", xToday)
      .attr("y1", 0)
      .attr("x2", xToday)
      .attr("y2", height + 15)

    svg.attr("height", height + 30)
  }

  return { draw }
}
