import { Renderer, Rendition } from "../renderer"
import { Widget } from "../widget"
import { createPalette, Coloring, createColoring } from "../color"
import { select, selectAll, event } from "d3-selection"
import { hsl } from "d3-color"
import d3KitTimeline from "d3kit-timeline"
import type { Axis } from "d3-axis"

interface HistoryEvent {
  id: number
  href: string

  type: string
  old?: boolean
  cat: number
  date: string
  text: string
  class: string
  desc?: string
  query: {
    id: number
    source: string
    url: string
  }
}

interface HistoryData {
  minDate: string
  maxDate: string
  event: HistoryEvent[]
  title: string
}

export const history: Renderer = function (
  widget: Widget,
  data: HistoryData
): Rendition {
  const container = widget.container

  const linkifier = widget.getLinkifier()
  let id = 1
  for (const d of data.event) {
    //d.href = linkifier(d)
    d.id = id++
  }

  const minDate = new Date(data.minDate)
  const maxDate = new Date(data.maxDate)

  if (!d3KitTimeline) {
    throw new Error("d3kit-timeline not found in global context which is ")
  }

  const vertical = false // data.event.length > 20;

  const paletteSize = 12
  const palette = createPalette(widget.getRootColor(), paletteSize)
  const greyColoring = createColoring(hsl("#888888"))
  function eventColoring(event: HistoryEvent): Coloring {
    return event.old ? greyColoring : palette[event.cat % paletteSize]
  }
  const event2color = (event: HistoryEvent) => eventColoring(event).standard

  const alternatingTickLength = 10
  const margin = { left: 12, right: 15, top: 5, bottom: 35 }

  function draw() {
    container.innerHTML = ""
    const chart = new d3KitTimeline(container, {
      margin,
      offset: [0, 0],
      initialHeight: 50,
      direction: vertical ? "right" : "up",
      domain: [minDate, maxDate],
      dotRadius: 5,
      linkColor: event2color,
      dotColor: event2color,
      labelBgColor: event2color,
      layerGap: 20,
      timeFn: (event: HistoryEvent) => new Date(event.date),
      textFn: (event: HistoryEvent) => event.text,
      formatAxis: (axis: Axis<Date>) => {
        const yearRange = maxDate.getFullYear() - minDate.getFullYear()
        return axis
          .tickFormat(widget.i18n().timeScaleFormatter)
          .tickSizeInner(8)
          .tickSizeOuter(10)
          .ticks(yearRange <= 4 ? 2 * yearRange : undefined)
      },
    })
    chart.off("data").off("options") // disable these events to prevent unnecessary calls of vizualize()

    chart.chartRoot.style("line-height", null)
    chart.container.style("line-height", null)

    chart.svg.insert("title", ":first-child").text(data.title)

    chart.data(data.event)

    function fitChart() {
      chart
        .fit() // fit chart to container size
        .options({
          labella: {
            maxPos: chart.width() - (margin.left + margin.right),
          },
        }) // new maxPos i.e. maxX in our case
        .updateDimensionNow() // write size to DOM and call visualize implicitly
        .resizeToFit() // sets the height of the container and also triggers a deferred call of updateDimension and visualize
        .updateDimensionNow()

      // Keep tick labels readable by alternating the length of the ticks
      // once the available space per tick falls below a threshold of 32px
      const ticks = chart.layers.get("main/axis").selectAll("g.tick")
      const widthPerTick = chart.width() / ticks.size()
      if (widthPerTick <= 32) {
        ticks.each(function (this: SVGGElement, datum: any, i: number) {
          if (i % 2 !== 0) {
            return
          }

          const tick = select(this)
          const textNode = tick.select("text")
          const lineNode = tick.select("line")
          textNode.attr("y", Number(textNode.attr("y")) + alternatingTickLength)
          lineNode.attr(
            "y2",
            Number(lineNode.attr("y2")) + alternatingTickLength
          )
        })
      }
    }

    fitChart()

    chart.layers
      .get("main/axis")
      .attr("font-family", null)
      .attr("font-size", null)

    chart.layers
      .get("main/label")
      .selectAll("g.label-g")
      .each(function (this: SVGGElement) {
        const children = select(this)
          .selectAll<Element, unknown>(".label-g > *")
          .remove()

        const anchor = select<SVGGElement, { data: HistoryEvent }>(this)
          .append("svg:a")
          .attr("class", (d) => d.data.class)
          .attr("xlink:href", (d) => d.data.href)
          .attr("xlink:title", (d) => d.data.desc as string)

        anchor.append("title").text((d) => d.data.desc as string)

        children.each(function (this) {
          anchor.append(() => this)
        })
      })

    const publicationClickHandler = widget.handlerValue("publicationClick")
    const alwaysClickable = typeof publicationClickHandler === "function"
    select(container)
      .selectAll<SVGGElement, { data: HistoryEvent }>(".label-g")
      .attr("data-clickable", (node) =>
        alwaysClickable || widget.getClickHandler("h") ? true : (null as any)
      )
      .on("click", function (node) {
        if (widget.invokeClickHandler(node.data)) {
          event.preventDefault()
        } else {
          if (publicationClickHandler) {
            publicationClickHandler(node.data.query)
            event.preventDefault()
          }
        }
      })
      .on("mouseenter touchstart", function (node) {
        if (!event.target) {
          return
        }
        const coloring = eventColoring(node.data)
        // highlight label
        select(event.target).select("rect").style("fill", coloring.bright)
        // highlight link and dot
        const id = node.data.id
        select(container)
          .selectAll<Element, { data: HistoryEvent }>(".link-layer path")
          .filter((node) => node.data.id === id)
          .attr("data-highlighted", true)
        // highlight publications in pub table
        const publicationId = node.data.query && node.data.query.id
        if (publicationId) {
          selectAll("[data-publication-id='" + publicationId + "']").style(
            "background-color",
            coloring.light
          )
        }
      })
      .on("mouseleave touchend", function (node) {
        if (!event.target) {
          return
        }
        // unhighlight label
        const coloring = eventColoring(node.data)
        select(event.target).select("rect").style("fill", coloring.standard)
        // unhighlight link
        select(container)
          .selectAll(".link-layer path")
          .attr("data-highlighted", false)
        // unhighlight publication in pub table
        const publicationId = node.data.query && node.data.query.id
        if (publicationId) {
          selectAll("[data-publication-id='" + publicationId + "']").style(
            "background-color",
            null
          )
        }
      })

    let initialRender = true
    return function redraw() {
      if (!initialRender) {
        fitChart()
      }
      initialRender = false
    }
  }
  return { draw: draw() }
}
